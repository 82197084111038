<template>
	<ConfirmDialog
		v-model="showDialog"
		type="info"
		width="400px"
		@closeDialog="onClose"
		@click:outside="onClose"
	>
		<template v-slot:header>
			Ndrysho fjalëkalimin
		</template>

		<template v-slot:content>
			<v-form
				id="changePasswordForm"
				ref="changePasswordForm"
				class="mt-1"
				@submit.prevent="changePassword"
			>
				<v-row>
					<v-col cols="12">
						<v-text-field
							ref="passwordField"
							v-model="state.password"
							:append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:type="state.showPassword ? 'text' : 'password'"
							autofocus
							dense
							hide-details="auto"
							label="Fjalëkalimi i vjetër/aktual"
							outlined
							@input="state.showError = false"
							@click:append="state.showPassword = !state.showPassword"
						></v-text-field>
					</v-col>
					<v-col class="pt-1" cols="12">
						<v-text-field
							ref="newPasswordField"
							v-model="state.newPassword"
							:append-icon="state.showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:rules="rules.passwordRules"
							:type="state.showNewPassword ? 'text' : 'password'"
							dense
							hide-details="auto"
							label="Fjalëkalimi i ri"
							outlined
							validate-on-blur
							@input="state.showError = false"
							@click:append="state.showNewPassword = !state.showNewPassword"
						></v-text-field>
					</v-col>
				</v-row>

				<div class="pt-2">
					<Alert :msg="state.errorMessage" :show="state.showError">
						{{ state.errorMessage }}
					</Alert>
				</div>
			</v-form>
		</template>

		<template v-slot:actions>
			<v-btn
				:disabled="state.isLoading"
				:loading="state.isLoading"
				class="full-width"
				color="primary"
				form="changePasswordForm"
				type="submit"
				@click="changePassword"
			>
				Ndrysho
			</v-btn>
		</template>
	</ConfirmDialog>
</template>

<script>
import ConfirmDialog from "../general/ConfirmDialog";
import Alert from "../general/Alert";
import { computed, reactive } from "@vue/composition-api";
import { displayErrorMessage } from "../../utilities/error-msg.utilities";
import { rules } from "../../utilities/form-validations.utilities";
import FlashService from "../../services/flash.service";

export default {
	name: "ChangePassword",
	components: { ConfirmDialog, Alert },
	props: ["value"],
	setup(props, { root, refs, emit }) {
		const state = reactive({
			isLoading: false,

			showPassword: false,
			password: null,

			showNewPassword: false,
			newPassword: null,

			errorMessage: "",
			showError: false
		});

		const showDialog = computed({
			get: () => props.value,
			set: value => {
				emit("input", value);
			}
		});

		async function changePassword() {
			if (!refs["changePasswordForm"]?.validate()) return;

			if (state.newPassword === state.password) {
				state.errorMessage =
					"Fjalëkalimi i ri nuk duhet të jetë i njëjtë me fjalëkalimin e vjetër!";
				state.showError = true;
				return;
			}

			state.isLoading = true;

			try {
				const response = await root.$store.dispatch("user/changePassword", {
					currentPassword: state.password,
					newPassword: state.newPassword
				});
				if (response.status === 200) {
					// showDialog.value = false;

					FlashService.push({
						type: "success",
						msg:
							"Fjalëkalimi u ndryshua me sukses!<br>Hyni përsëri me kredencialet e reja."
					});

					await root.$store.dispatch("auth/logOut");

					refs["changePasswordForm"]?.reset();
				}
			} catch (err) {
				state.errorMessage = displayErrorMessage(err);
				refs["passwordField"]?.focus();
				state.password = null;
				state.newPassword = null;
				state.showError = true;
				return false;
			} finally {
				setTimeout(() => {
					state.isLoading = false;
				}, 300);
			}
		}

		function onClose() {
			showDialog.value = false;
			refs["changePasswordForm"]?.reset();
		}

		return {
			state,

			showDialog,
			changePassword,
			rules,

			onClose
		};
	}
};
</script>

<style scoped></style>
