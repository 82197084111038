<template>
	<div>
		<v-tooltip bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					icon
					v-bind="attrs"
					v-on="on"
					@click="fetchData"
					:class="state.spinAnimation"
					:disabled="state.isFetchingData"
				>
					<v-icon>mdi-cached</v-icon>
					<!--             {{ state.isFetchingData ? "mdi-spin" : "" }} -->
				</v-btn>
			</template>
			<span>Rifresko</span>
		</v-tooltip>
	</div>
</template>

<script>
import { onMounted, reactive } from "@vue/composition-api";
import FlashService from "../../services/flash.service";
import UserService from "../../services/user.service";

export default {
	name: "SyncButton",
	setup(props, { root, emit }) {
		const state = reactive({
			isFetchingData: false,
			lastFetchTime: 0,
			spinAnimation: ""
		});

		onMounted(async () => {
			await fetchData(false);
		});

		async function fetchData(fetchUserData = true) {
			state.isFetchingData = true;
			state.lastFetchTime = Date.now();
			state.spinAnimation = "spinning";
			console.log("Synchronizing...");

			// Fetch conditionally?
			try {
				if (fetchUserData) await UserService.fetchCurrentUserData();
				await root.$store.dispatch("classrooms/fetchClassrooms");
				await root.$store.dispatch("notifications/fetchNotifications");
			} catch (err) {
				if (err.message === "NETWORK_ERROR") {
					FlashService.push({
						type: "error",
						msg: "Mungon lidhja me serverin!"
					});
				}
			} finally {
				// End animation
				const difference = Date.now() - state.lastFetchTime;
				setTimeout(() => {
					state.spinAnimation = "";
					state.isFetchingData = false;
				}, Math.ceil(difference / 1000) * 1000 - difference);
			}
		}

		return {
			state,

			fetchData
		};
	}
};
</script>

<style lang="scss" scoped>
.spinning {
	animation: 1s spin infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(-360deg);
	}
}
</style>
