<template>
	<!-- Desktop/tablet view -->
	<div>
		<!-- Nav drawer toggle btn -->
		<v-hover
			v-if="!$isMobileOnly && $screen.width >= 900"
			v-slot="{ hover }"
			close-delay="200"
		>
			<v-btn
				fixed
				fab
				depressed
				color="primary"
				width="35px"
				height="35px"
				class="nav-drawer-toggle"
				:class="[
					{ 'nav-closed': !state.openDrawer },
					{ 'on-hover': hover && !state.openDrawer },
				]"
				:style="!state.openDrawer ? 'left: -18px' : ''"
				@click="toggleDrawer"
			>
				<v-icon>
					{{ state.openDrawer ? "mdi-chevron-left" : "mdi-chevron-right" }}
				</v-icon>
			</v-btn>
		</v-hover>

		<v-navigation-drawer
			v-if="!$isMobileOnly && state.openDrawer && $screen.width >= 900"
			id="main-nav-drawer"
			app
			floating
			color="navDrawer"
			permanent
			touchless
			width="80px"
		>
			<v-list dense nav flat>
				<v-list-item-group>
					<v-list-item
						v-for="item in state.items"
						:key="item.title"
						:ripple="false"
						:to="`/${item.to}`"
						class="nav-list rounded-0"
						link
					>
						<v-list-item-icon>
							<v-icon color="textLight" large>{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<!-- Mobile phone view -->
		<v-bottom-navigation
			v-else-if="$isMobileOnly || $screen.width < 900"
			id="mobile-bottom-drawer"
			app
			background-color="navDrawerMobile"
			color="primaryLight"
			grow
		>
			<v-btn
				v-for="item in state.items"
				:key="item.title"
				:ripple="false"
				:to="`/${item.to}`"
				class="px-1"
				style="min-width: 10px"
			>
				<span>{{ item.title }}</span>
				<v-icon>{{ item.icon }}</v-icon>
			</v-btn>
		</v-bottom-navigation>
	</div>
</template>

<script>
import { reactive } from "@vue/composition-api";

export default {
	name: "AppNavigationDrawer",
	setup(props, { root, emit }) {
		const state = reactive({
			items: [
				{ title: "Klasa", icon: "mdi-google-classroom", to: "classrooms" },
				{ title: "Komunikim", icon: "mdi-forum-outline", to: "forum" },
				{
					title: "Materiale",
					icon: "mdi-file-document-multiple-outline",
					to: "materials",
				},
				{
					title: "Libra interaktivë",
					icon: "mdi-book-open-outline",
					to: "interactive-books",
				},
				{
					title: "Kalendari",
					icon: "mdi-calendar-month-outline",
					to: "calendar",
				},
				{ title: "Risi digjitale", icon: "mdi-help-box", to: "whats-new" },
			],
			openDrawer: true,
		});

		function toggleDrawer() {
			state.openDrawer = !state.openDrawer;
			if (!state.openDrawer) {
				root.$emit("drawerOpened", true);
			} else {
				root.$emit("drawerOpened", false);
			}
		}

		return {
			state,

			toggleDrawer,
		};
	},
};
</script>

<style lang="scss" scoped>
.nav-drawer-toggle {
	top: calc(50% - 17px);
	left: 62px;
	z-index: 999;
	opacity: 0.8;
	transition: 0.1s all;

	&:hover {
		opacity: 1 !important;
	}
}

.on-hover {
	left: 1px !important;
}

.disappear {
	margin-left: -80px;
}

#main-nav-drawer::v-deep {
	z-index: 998 !important;
	//box-shadow: 4px 0 10px -4px rgba(19, 19, 19, 0.2);
	border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
	overflow: initial;

	.v-navigation-drawer__content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.v-list {
			padding: 0;

			.nav-list {
				display: flex;
				flex-direction: column;
				align-content: center;
				padding: 8px 2px 8px 6px;
				margin: 0;

				&.v-list-item:before {
					background-color: transparent !important;
				}

				.v-list-item__content {
					padding: 2px;

					.v-list-item__title {
						font-size: .8rem !important;
					}
				}

				&.v-list-item--active {
					background-color: var(--v-background-base) !important;
					position: relative;

					i,
					.v-list-item__title {
						color: var(--v-primaryLight-base) !important;
					}

					&:before {
						content: "";
						display: block;
						position: absolute;
						width: 2px;
						height: 80%;
						top: 50%;
						transform: translateY(-50%);
						left: 4px;
						background-color: var(--v-primaryLight-base) !important;
						opacity: 1 !important;
					}
				}

				&:after {
					display: none;
				}
			}

			.v-list-item__icon {
				margin-right: 0;
				align-self: center;
			}

			.v-list-item__content {
				.v-list-item__title {
					color: var(--v-textLight-base);
					text-align: center;
					white-space: normal;
				}
			}
		}

		@media only screen and (max-height: 660px) {
			margin-top: 64px;

			.nav-list {
				padding: 6px !important;
			}

			.v-list-item__content {
				padding: 2px 0;

				.v-list-item__title {
					display: none;
				}
			}
		}
	}
}

#mobile-bottom-drawer::v-deep {
	z-index: 997;

	.v-btn__content {
		min-width: 0;
		width: 100%;

		& > span {
			width: 100%;
			text-align: center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	//.v-btn:not(.v-btn--active) {
	//  &:not(.v-btn--active) span {
	//    color: var(--v-text-base);
	//  }
	//
	//  & > i {
	//    color: var(--v-text-base);
	//  }
	//}

	.v-btn:before {
		background-color: transparent !important;
		transition: none;
	}

	.v-btn.v-btn--active {
		background-color: var(--v-background-base) !important;
	}
}

.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
	color: #ececec !important;
}
</style>
