<template>
	<div>
		<v-menu
			v-model="state.menu"
			:close-on-content-click="false"
			attach=""
			offset-y
			:min-width="$isMobileOnly ? '100%' : '400px'"
			:max-width="$isMobileOnly ? '100%' : '600px'"
			min-height="200px"
			:max-height="
				$isMobileOnly ? 'calc(100vh - 300px)' : 'calc(100vh - 300px)'
			"
			:nudge-bottom="$isMobileOnly ? '4px' : '8px'"
			transition="scroll-y-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-badge
					:class="{ 'badge-mobile': $isMobileOnly }"
					color="deep-orange accent-3"
					:content="state.notifications.length"
					:value="state.notifications.length > 0"
					:offset-x="$isMobileOnly ? 17 : 16"
					:offset-y="$isMobileOnly ? 17 : 16"
					overlap
				>
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="pa-0"
						icon
						@click="
							$isMobileOnly
								? (state.showNotifications = !state.showNotifications)
								: ''
						"
					>
						<v-icon>mdi-bell</v-icon>
					</v-btn>
				</v-badge>
			</template>

			<v-card class="rounded-0" style="z-index: 998">
				<v-list class="py-0" color="background">
					<v-list-item v-if="state.notifications.length === 0">
						<v-list-item-content class="notification-wrapper">
							Nuk keni asnjë njoftim
						</v-list-item-content>
					</v-list-item>

					<div
						v-for="(notification, index) of state.notifications"
						:key="`notification-${notification.id}`"
						:style="
							!notification.isRead
								? 'background-color: rgba(160, 160, 160, 0.15); '
								: ''
						"
					>
						<v-divider></v-divider>
						<v-list-item dense @click="">
							<v-list-item-content class="notification-wrapper">
								<div class="notification-title">
									{{ notification.title }}
								</div>
								<div class="notification-content">
									{{ notification.content }}
								</div>
								<div class="notification-date">
									{{ `${timeSince(new Date(notification.createdAt))} më parë` }}
								</div>
								<div class="notification-remove">
									<v-btn icon small absolute @mousedown.stop="">
										<v-icon small>mdi-close</v-icon>
									</v-btn>
								</div>
							</v-list-item-content>
						</v-list-item>
					</div>
				</v-list>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";
import { timeSince } from "../../utilities/general.utilities";

export default {
	name: "NotificationsMenu",
	setup(props, { root }) {
		const state = reactive({
			menu: false,
			showNotifications: false,

			notifications: computed(() =>
				root.$clone(root.$store.getters["notifications/getNotifications"])
			)
		});

		return {
			state,
			timeSince
		};
	}
};
</script>

<style lang="scss" scoped>
.v-menu__content {
	//margin-top: 3px;
	border-radius: 0 0 10px 10px;
	//box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 6%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
	left: auto !important;
	right: 0 !important;

	.v-list-item {
		padding: 0 0;

		.notification-wrapper {
			position: relative;
			padding: 10px 16px;

			.notification-title {
				font-size: 1rem;
				padding: 6px 0;
				font-weight: 500;
			}

			.notification-content {
				font-size: .9rem;
				padding-bottom: 4px;
				opacity: 0.8;
			}

			.notification-date {
				font-size: .8rem;
				text-align: right;
				font-weight: 500;
				opacity: 0.6;
			}

			.notification-remove {
				position: absolute;
				top: 6px;
				right: 28px;
			}
		}
	}
}

.badge-mobile::v-deep {
	.v-badge__wrapper {
		.v-badge__badge {
			height: 16px;
			width: 16px;
			padding: 4px;
			font-size: 10px;
			min-width: auto;
			min-height: auto;
		}
	}
}
</style>
