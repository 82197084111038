import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"app-bar",staticStyle:{"transition":"none"},style:(!_vm.$isMobileOnly &&
		_vm.$screen.width >= 900 &&
		_vm.state.isDrawerOpened &&
		_vm.state.path !== '/login'
			? 'margin-left: -79px;'
			: ''),attrs:{"app":"","color":"appHeader","dark":"","flat":""}},[_c('div',{staticClass:"d-flex align-center justify-space-between full-width"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"min-width":"50px"}},[_c(VIcon,[_vm._v("mdi-school")]),_c(VAppBarTitle,{staticClass:"app-bar-title",class:_vm.$isMobileOnly ? 'ml-2' : 'ml-4'},[_vm._v(" Shkolla Virtuale ")])],1),(!_vm.$isMobileOnly && _vm.state.path !== '/login')?_c(VCombobox,{staticClass:"mx-8",staticStyle:{"max-width":"50%"},attrs:{"background-color":"background","id":"app-search","attach":"","dense":"","hide-details":"","hide-no-data":"","light":!this.$vuetify.theme.dark,"placeholder":"Kërko","prepend-inner-icon":"mdi-magnify","solo":""}}):_vm._e(),(_vm.state.path !== '/login')?_c('div',{staticClass:"d-flex align-center"},[_c('SyncButton',{class:_vm.$isMobileOnly ? 'mr-' : 'mr-2'}),_c('NotificationsMenu',{class:_vm.$isMobileOnly ? 'mr-2' : 'mr-4'}),_c('ProfileMenu',{staticClass:"ml-2"})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }