<template>
	<Dialog v-model="showDialog" width="600px" @closeDialog="onSettingsClosed">
		<template v-slot:header> Përditëso profilin </template>

		<!--  Content -->
		<template v-slot:content>
			<v-form
				id="settingsForm"
				ref="settingsForm"
				@submit.prevent="validateFields"
			>
				<v-row>
					<!--        <v-col cols="3" class="pa-0"> &lt;!&ndash; style="background-color: var(&#45;&#45;v-primary-base)!important; &ndash;&gt;-->
					<!--          <v-list-item-group mandatory color="white">-->
					<!--            <v-list-item v-for="i in 5" :key="`setting-${i}`" class="list-item" :active-class="'selected-list'">-->
					<!--              Setting {{ i }}-->
					<!--            </v-list-item>-->
					<!--          </v-list-item-group>-->
					<!--        </v-col>-->
					<!--        <v-divider vertical></v-divider>-->
					<!--        <v-col cols="9">-->

					<!--        </v-col>-->
					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							ref="firstNameField"
							v-model="state.userData.firstName"
							:readonly="state.firstNameRO"
							:rules="rules.nameRules"
							append-icon="mdi-pencil"
							dense
							hide-details="auto"
							label="Emri"
							outlined
							prepend-inner-icon="mdi-format-letter-case"
							@click:append="
								state.firstNameRO = false;
								$refs.firstNameField.focus();
							"
						>
						</v-text-field>
					</v-col>
					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							ref="lastNameField"
							v-model="state.userData.lastName"
							:readonly="state.lastNameRO"
							:rules="rules.lastNameRules"
							append-icon="mdi-pencil"
							dense
							hide-details="auto"
							label="Mbiemri"
							outlined
							prepend-inner-icon="mdi-format-letter-case"
							@click:append="
								state.lastNameRO = false;
								$refs.lastNameField.focus();
							"
						>
						</v-text-field>
					</v-col>
					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							ref="fatherNameField"
							v-model="state.userData.fatherhood"
							:readonly="state.fatherhoodRO"
							:rules="rules.fatherNameRules"
							append-icon="mdi-pencil"
							dense
							hide-details="auto"
							label="Atësia"
							outlined
							prepend-inner-icon="mdi-human-male-boy"
							@click:append="
								state.fatherNameRO = false;
								$refs.fatherNameField.focus();
							"
						>
						</v-text-field>
					</v-col>
					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							ref="schoolField"
							v-model="state.userData.school"
							:readonly="state.schoolRO"
							:rules="rules.schoolNameRules"
							append-icon="mdi-pencil"
							dense
							hide-details="auto"
							label="Shkolla"
							outlined
							prepend-inner-icon="mdi-school"
							@click:append="
								state.schoolRO = false;
								$refs.schoolField.focus();
							"
						>
						</v-text-field>
					</v-col>

					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							ref="cityField"
							v-model="state.userData.city"
							:readonly="state.cityRO"
							:rules="rules.cityNameRules"
							append-icon="mdi-pencil"
							dense
							hide-details="auto"
							label="Qyteti"
							outlined
							prepend-inner-icon="mdi-city"
							@click:append="
								state.cityRO = false;
								$refs.cityField.focus();
							"
						>
						</v-text-field>
					</v-col>

					<v-col :cols="$isMobileOnly ? 12 : 6">
						<v-text-field
							v-model="state.userData.email"
							dense
							disabled
							hide-details="auto"
							label="E-mail"
							outlined
							prepend-inner-icon="mdi-email"
							readonly
						>
						</v-text-field>
					</v-col>
				</v-row>
			</v-form>
		</template>

		<template v-slot:actions>
			<v-row class="my-0">
				<!-- Save changes, triggers password confirm dialog -->
				<v-col class="py-2 text-center" cols="12">
					<v-btn
						:disabled="
							state.confirmPasswordDialog ||
							!state.hasChanges ||
							state.savingChanges
						"
						:loading="state.savingChanges"
						class="full-width"
						color="primary"
						form="settingsForm"
						type="submit"
						@click="validateFields"
					>
						<v-icon left>mdi-content-save-outline</v-icon>
						Ruaj ndryshimet
					</v-btn>
				</v-col>

				<!-- Change password -->
				<v-col class="py-2 text-center" cols="12">
					<v-btn
						:disabled="state.changePasswordDialog"
						class="full-width"
						color="warning"
						@click="state.changePasswordDialog = true"
					>
						<v-icon left>mdi-lock-reset</v-icon>
						Ndrysho fjalëkalimin
					</v-btn>
				</v-col>

				<!-- Change Password Dialog -->
				<ChangePassword v-model="state.changePasswordDialog" />

				<!-- Confirm Password Dialog -->
				<ConfirmPassword
					v-model="state.confirmPasswordDialog"
					@passwordIsValid="saveChanges"
				/>

				<!-- Delete Account -->
				<v-col class="py-2 text-center" cols="12">
					<v-btn class="full-width" color="error">
						<v-icon left>mdi-trash-can-outline</v-icon>
						Fshij llogarinë
					</v-btn>
				</v-col>
			</v-row>
		</template>
	</Dialog>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";
import Dialog from "../general/Dialog";
import ConfirmDialog from "../general/ConfirmDialog";
import ConfirmPassword from "./ConfirmPassword";
import { rules } from "../../utilities/form-validations.utilities";
import ChangePassword from "./ChangePassword";

export default {
	name: "ProfileSettings",
	components: { ChangePassword, ConfirmPassword, ConfirmDialog, Dialog },
	props: ["value"],
	setup(props, { root, refs, emit }) {
		const state = reactive({
			initialUserData: computed(
				() => root.$store.getters["user/getCurrentUserData"]
			),
			userData: root.$clone(root.$store.getters["user/getCurrentUserData"]),
			currentPassword: null,
			newPassword: null,

			confirmPasswordDialog: false,
			changePasswordDialog: false,

			hasChanges: false,
			savingChanges: false,

			// Read only
			firstNameRO: true,
			lastNameRO: true,
			fatherNameRO: true,
			schoolRO: true,
			cityRO: true,
		});

		watch(
			() => state.userData,
			(userData) => {
				// console.log(deepEqual(userData, root.$store.getters['user/getCurrentUserData']))
				state.hasChanges = !root.$equal(userData, state.initialUserData);
			},
			{ deep: true }
		);

		const showDialog = computed({
			get: () => props.value,
			set: (value) => {
				emit("input", value);
			},
		});

		function validateFields() {
			if (!refs["settingsForm"]?.validate()) return;

			state.confirmPasswordDialog = true;
		}

		async function saveChanges() {
			// state.confirmPasswordDialog = true;
			state.savingChanges = true;

			try {
				await root.$store.dispatch("user/updateProfile", state.userData);
				state.hasChanges = false;
			} catch (err) {
				state.hasChanges = true;
			} finally {
				state.savingChanges = false;
			}
		}

		function onSettingsClosed() {
			showDialog.value = false;
			refs.settingsForm.resetValidation();
			state.userData = root.$clone(state.initialUserData);
		}

		return {
			state,
			showDialog,
			rules,

			validateFields,
			saveChanges,
			onSettingsClosed,
		};
	},
};
</script>

<style lang="scss" scoped>
.list-item {
	color: white !important;
}

.selected-list {
	background-color: white;
	color: var(--v-primary-base) !important;
}
</style>
