<template>
	<div class="fill-height layout-wrapper">
		<AppHeader />

		<AppNavigationDrawer v-if="$route.fullPath !== '/login'" />

		<v-main class="fill-height">
			<transition name="fade" mode="out-in">
				<Preloader
					v-if="
						isLoading &&
							($route.matched[1].name !== 'classrooms' ||
								$route.matched.length === 2)
					"
				/>
				<router-view v-else class="fill-height"></router-view>
			</transition>
		</v-main>
	</div>
</template>

<script>
import AppHeader from "./AppHeader";
import AppNavigationDrawer from "./AppNavigationDrawer";
import Preloader from "../general/Preloader";
import { computed } from "@vue/composition-api";

export default {
	name: "AppLayout",
	components: { AppNavigationDrawer, AppHeader, Preloader },
	setup(props, { root }) {
		const isLoading = computed(() => root.$store.getters["general/isLoading"]);

		return {
			isLoading
		};
	}
};
</script>

<style lang="scss" scoped>
.layout-wrapper {
	max-height: 100vh;
	overflow: hidden;
	position: relative;
}
</style>
