import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"close-on-content-click":false,"attach":"","offset-y":"","min-width":_vm.$isMobileOnly ? '100%' : '400px',"max-width":_vm.$isMobileOnly ? '100%' : '600px',"min-height":"200px","max-height":_vm.$isMobileOnly ? 'calc(100vh - 300px)' : 'calc(100vh - 300px)',"nudge-bottom":_vm.$isMobileOnly ? '4px' : '8px',"transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{class:{ 'badge-mobile': _vm.$isMobileOnly },attrs:{"color":"deep-orange accent-3","content":_vm.state.notifications.length,"value":_vm.state.notifications.length > 0,"offset-x":_vm.$isMobileOnly ? 17 : 16,"offset-y":_vm.$isMobileOnly ? 17 : 16,"overlap":""}},[_c(VBtn,_vm._g(_vm._b({staticClass:"pa-0",attrs:{"icon":""},on:{"click":function($event){_vm.$isMobileOnly
							? (_vm.state.showNotifications = !_vm.state.showNotifications)
							: ''}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-bell")])],1)],1)]}}]),model:{value:(_vm.state.menu),callback:function ($$v) {_vm.$set(_vm.state, "menu", $$v)},expression:"state.menu"}},[_c(VCard,{staticClass:"rounded-0",staticStyle:{"z-index":"998"}},[_c(VList,{staticClass:"py-0",attrs:{"color":"background"}},[(_vm.state.notifications.length === 0)?_c(VListItem,[_c(VListItemContent,{staticClass:"notification-wrapper"},[_vm._v(" Nuk keni asnjë njoftim ")])],1):_vm._e(),_vm._l((_vm.state.notifications),function(notification,index){return _c('div',{key:("notification-" + (notification.id)),style:(!notification.isRead
							? 'background-color: rgba(160, 160, 160, 0.15); '
							: '')},[_c(VDivider),_c(VListItem,{attrs:{"dense":""},on:{"click":function($event){}}},[_c(VListItemContent,{staticClass:"notification-wrapper"},[_c('div',{staticClass:"notification-title"},[_vm._v(" "+_vm._s(notification.title)+" ")]),_c('div',{staticClass:"notification-content"},[_vm._v(" "+_vm._s(notification.content)+" ")]),_c('div',{staticClass:"notification-date"},[_vm._v(" "+_vm._s(((_vm.timeSince(new Date(notification.createdAt))) + " më parë"))+" ")]),_c('div',{staticClass:"notification-remove"},[_c(VBtn,{attrs:{"icon":"","small":"","absolute":""},on:{"mousedown":function($event){$event.stopPropagation();}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)])],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }