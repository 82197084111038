/** Same validations on the backend **/

const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+$/u;
const emailRegex = /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;

// Minimum eight characters, at least one uppercase letter, one lowercase letter and one number
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[\w\s]).{8,}$/;

// First name rules
let nameRules = [
	v => !!v || "Emri nuk mund të jetë bosh!",
	v => v?.length <= 20 || "Emri nuk mund të ketë më shumë se 20 karaktere!",
	v => nameRegex.test(String(v)) || "Emri nuk është në formatin e duhur!"
];

// Last name rules
let lastNameRules = [
	v => !!v || "Mbiemri nuk mund të jetë bosh!",
	v => v?.length <= 20 || "Mbiemri nuk mund të ketë më shumë se 20 karaktere!",
	v => nameRegex.test(String(v)) || "Mbiemri nuk është në formatin e duhur!"
];

// City name rules
let cityNameRules = [
	v => !!v || "Emri i qytetit nuk mund të jetë bosh!",
	v =>
		v?.length <= 20 ||
		"Emri i qytetit nuk mund të ketë më shumë se 20 karaktere!"
];

// Father's name rules
let fatherNameRules = [
	v => !!v || "Emri i babait nuk mund të jetë bosh!",
	v =>
		v?.length <= 20 ||
		"Emri i babait nuk mund të ketë më shumë se 20 karaktere!"
];

// School name rules
let schoolNameRules = [
	v => !!v || "Emri i shkollës nuk mund të jetë bosh!",
	v =>
		v?.length <= 20 ||
		"Emri i shkollës nuk mund të ketë më shumë se 20 karaktere!"
];

// E-mail rules
let emailRules = [
	v => !!v || "E-mail nuk mund të jetë bosh!",
	v => v?.length <= 256 || "E-mail nuk mund të ketë më shumë se 256 karaktere!",
	v =>
		emailRegex.test(String(v).toLowerCase()) ||
		"E-mail nuk është në formatin e duhur!"
];

// School name rules
let passwordRules = [
	v => !!v || "Fjalëkalimi nuk mund të jetë bosh!",
	v => v?.length >= 8 || "Fjalëkalimi duhet të ketë 8 ose më shumë karaktere!",
	v =>
		passwordRegex.test(v) ||
		"Fjalëkalimi duhet të ketë të paktën një shkronjë kapitale, një shkronjë jo-kapitale dhe një numër."
];

export const rules = {
	nameRules: nameRules,
	lastNameRules: lastNameRules,
	emailRules: emailRules,
	cityNameRules: cityNameRules,
	fatherNameRules: fatherNameRules,
	schoolNameRules: schoolNameRules,
	passwordRules: passwordRules
};
