<template>
	<v-app-bar
		app
		class="app-bar"
		color="appHeader"
		dark
		flat
		:style="
			!$isMobileOnly &&
			$screen.width >= 900 &&
			state.isDrawerOpened &&
			state.path !== '/login'
				? 'margin-left: -79px;'
				: ''
		"
		style="transition: none"
	>
		<div class="d-flex align-center justify-space-between full-width">
			<div class="d-flex align-center" style="min-width: 50px">
				<v-icon>mdi-school</v-icon>
				<v-app-bar-title
					class="app-bar-title"
					:class="$isMobileOnly ? 'ml-2' : 'ml-4'"
				>
					Shkolla Virtuale
				</v-app-bar-title>
			</div>

			<!--  Search TODO: Move to a separate component or remove -->
			<v-combobox
				v-if="!$isMobileOnly && state.path !== '/login'"
				background-color="background"
				id="app-search"
				attach=""
				class="mx-8"
				dense
				hide-details
				hide-no-data
				:light="!this.$vuetify.theme.dark"
				placeholder="Kërko"
				prepend-inner-icon="mdi-magnify"
				style="max-width: 50%"
				solo
			></v-combobox>

			<div v-if="state.path !== '/login'" class="d-flex align-center">
				<SyncButton :class="$isMobileOnly ? 'mr-' : 'mr-2'" />
				<NotificationsMenu :class="$isMobileOnly ? 'mr-2' : 'mr-4'" />

				<ProfileMenu class="ml-2" />
			</div>
		</div>
	</v-app-bar>
</template>

<script>
import ProfileMenu from "../profile/ProfileMenu";
import {
	computed,
	onMounted,
	onUnmounted,
	reactive
} from "@vue/composition-api";
import NotificationsMenu from "../profile/NotificationsMenu";
import SyncButton from "../general/SyncButton";

export default {
	name: "AppHeader",
	components: { SyncButton, NotificationsMenu, ProfileMenu },
	setup(props, { root, emit }) {
		const state = reactive({
			path: computed(() => root.$route.path),

			isDrawerOpened: true
		});

		onMounted(() => {
			root.$on("drawerOpened", isOpened => {
				state.isDrawerOpened = !isOpened;
			});
		});

		onUnmounted(() => {
			root.$off("drawerOpened");
		});

		return {
			state
		};
	}
};
</script>

<style lang="scss" scoped>
.app-bar::v-deep {
	z-index: 999 !important;

	.v-toolbar__content {
		height: 100% !important;
	}
}

.app-bar-title::v-deep {
	width: 200px;

	.v-app-bar-title__content {
		width: 200px !important;
	}

	@media only screen and (max-width: 360px) {
		//display: none;
		font-size: 18px;
	}
}

.v-badge::v-deep {
	.v-badge__wrapper {
		.v-badge__badge {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px;
			height: 18px;
			width: 18px;
			min-width: auto;
			inset: auto auto calc(100% - 18px) calc(100% - 18px) !important;
		}
	}
}
</style>
