import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$isMobileOnly && _vm.$screen.width >= 900)?_c(VHover,{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VBtn,{staticClass:"nav-drawer-toggle",class:[
				{ 'nav-closed': !_vm.state.openDrawer },
				{ 'on-hover': hover && !_vm.state.openDrawer } ],style:(!_vm.state.openDrawer ? 'left: -18px' : ''),attrs:{"fixed":"","fab":"","depressed":"","color":"primary","width":"35px","height":"35px"},on:{"click":_vm.toggleDrawer}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.state.openDrawer ? "mdi-chevron-left" : "mdi-chevron-right")+" ")])],1)]}}],null,false,1778256739)}):_vm._e(),(!_vm.$isMobileOnly && _vm.state.openDrawer && _vm.$screen.width >= 900)?_c(VNavigationDrawer,{attrs:{"id":"main-nav-drawer","app":"","floating":"","color":"navDrawer","permanent":"","touchless":"","width":"80px"}},[_c(VList,{attrs:{"dense":"","nav":"","flat":""}},[_c(VListItemGroup,_vm._l((_vm.state.items),function(item){return _c(VListItem,{key:item.title,staticClass:"nav-list rounded-0",attrs:{"ripple":false,"to":("/" + (item.to)),"link":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"textLight","large":""}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1):(_vm.$isMobileOnly || _vm.$screen.width < 900)?_c(VBottomNavigation,{attrs:{"id":"mobile-bottom-drawer","app":"","background-color":"navDrawerMobile","color":"primaryLight","grow":""}},_vm._l((_vm.state.items),function(item){return _c(VBtn,{key:item.title,staticClass:"px-1",staticStyle:{"min-width":"10px"},attrs:{"ripple":false,"to":("/" + (item.to))}},[_c('span',[_vm._v(_vm._s(item.title))]),_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }