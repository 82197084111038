<template>
	<div v-if="state.userData">
		<v-menu
			v-model="state.menu"
			:close-on-content-click="false"
			:nudge-width="$isMobileOnly ? '100%' : 330"
			attach=""
			offset-y
			:min-width="$isMobileOnly ? '100%' : ''"
			:nudge-bottom="$isMobileOnly ? '0px' : '5px'"
			transition="scroll-y-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-badge
					bottom
					class="status-badge"
					color="positive"
					dot
					offset-x="11"
					offset-y="11"
				>
					<v-btn
						v-bind="attrs"
						v-on="on"
						class="pa-0"
						depressed
						fab
						elevation="1"
						style="height: auto; width: auto"
						@click="
							$isMobileOnly ? (state.showProfile = !state.showProfile) : ''
						"
					>
						<v-avatar color="background" :size="$isMobileOnly ? '40' : '44'">
							<img
								v-if="state.userData.avatar"
								:src="state.userData.avatar"
								alt="User Logo"
							/>
							<span v-else class="text--text text-h5">
								{{
									getInitials(
										`${state.userData.firstName} ${state.userData.lastName}`
									)
								}}
							</span>
						</v-avatar>
					</v-btn>
				</v-badge>
			</template>

			<v-card class="rounded-0" color="background" style="z-index: 998">
				<v-divider></v-divider>

				<v-list class="pt-0" color="background">
					<v-list-item>
						<v-list-item-avatar>
							<UserAvatar :user-data="state.userData" />
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title
								>{{ state.userData.firstName + " " + state.userData.lastName }}
							</v-list-item-title>
							<v-list-item-subtitle
								>{{ state.userData.email }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-divider class="mb-2"></v-divider>

					<v-list-item dense>
						<v-icon color="green accent-4" x-small>mdi-circle</v-icon>
						<a class="ml-3" style="color: var(--v-text-base)">Online</a>
					</v-list-item>

					<v-list-item dense>
						<v-icon small>mdi-comment-edit-outline</v-icon>
						<a class="ml-2" style="color: var(--v-text-base)"
							>Vendos statusin</a
						>
					</v-list-item>

					<v-divider class="my-2"></v-divider>

					<v-list-item dense>
						<v-icon small>mdi-bookmark-outline</v-icon>
						<a class="ml-2" style="color: var(--v-text-base)">Saved</a>
					</v-list-item>

					<v-list-item dense>
						<v-icon small>mdi-cog-outline</v-icon>
						<a
							class="ml-2"
							@click="state.showSettings = true"
							style="color: var(--v-text-base)"
							>Konfigurime</a
						>
					</v-list-item>

					<v-divider class="my-2"></v-divider>

					<v-list-item dense>
						<v-icon small>mdi-brightness-6</v-icon>
						<span class="ml-2">Tema</span>
						<v-spacer></v-spacer>
						<v-switch
							:label="this.$vuetify.theme.dark ? 'Dark' : 'Light'"
							@change="toggleTheme"
							inset
							hide-details
						></v-switch>
					</v-list-item>
				</v-list>

				<v-card-actions>
					<v-btn color="error" width="100%" depressed @click="logOut">
						Dil
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-menu>

		<!-- Settings dialog -->
		<ProfileSettings v-model="state.showSettings" />
	</div>
</template>

<script>
import { computed, reactive } from "@vue/composition-api";
import ProfileSettings from "./ProfileSettings";
import { getInitials } from "../../utilities/general.utilities";
import UserAvatar from "./UserAvatar";

export default {
	name: "ProfileMenu",
	components: { UserAvatar, ProfileSettings },
	setup(props, { root }) {
		const state = reactive({
			menu: false,
			message: false,
			userData: computed(() => root.$store.getters["user/getCurrentUserData"]),
			loaded: false,
			showProfile: false,
			showSettings: false
		});

		function toggleTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			localStorage.setItem(
				"theme",
				this.$vuetify.theme.dark ? "dark" : "light"
			);
		}

		async function logOut() {
			await root.$store.dispatch("auth/logOut");
		}

		return {
			state,

			toggleTheme,
			logOut,

			getInitials
		};
	}
};
</script>

<style lang="scss" scoped>
.v-menu__content {
	margin-top: 6px;
	border-radius: 0 0 10px 10px;
	left: auto !important;
	right: 0 !important;
}

.v-list-item {
	min-height: 38px;

	a {
		color: rgba(0, 0, 0, 0.6);

		&:hover {
			text-decoration: underline;
		}
	}
}

.status-badge::v-deep {
	.v-badge__wrapper {
		.v-badge__badge {
			display: flex;
			justify-content: center;
			align-items: center;
			inset: calc(100% - 12px) auto auto calc(100% - 12px) !important;
			padding: 2px;
			height: 12px;
			width: 12px;
			border-radius: 50%;
			min-width: auto;
		}
	}
}
</style>
